/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"podpora"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-6g6cg6 --center pb--80 pt--80" name={"uvod"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/8339f4342b984840a78dbeb12a05c654_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/8339f4342b984840a78dbeb12a05c654_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/8339f4342b984840a78dbeb12a05c654_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/8339f4342b984840a78dbeb12a05c654_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/8339f4342b984840a78dbeb12a05c654_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/8339f4342b984840a78dbeb12a05c654_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/8339f4342b984840a78dbeb12a05c654_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/8339f4342b984840a78dbeb12a05c654_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" content={"<span style=\"color: rgb(255, 255, 255);\"><br><br><br></span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w pb--60 pt--60" name={"m4klc79pk"}>
          
          <ColumnWrap className="column__flex --center el--4" style={{"maxWidth":1510}} columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/22cd5158c4864a35a99549c2c0e25517_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/22cd5158c4864a35a99549c2c0e25517_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/15fb18b465b2486795af21258a65c3dc_e=0x9x504x504_bri=115_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/15fb18b465b2486795af21258a65c3dc_e=0x9x504x504_bri=115_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/6573f8f8221441e1b2f4d5dfd09dbd3e_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/6573f8f8221441e1b2f4d5dfd09dbd3e_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/868b04d953c544d69888c4512af30491_e=0x0x504x513_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/868b04d953c544d69888c4512af30491_e=0x0x504x513_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}